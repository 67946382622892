<template>      
	<form @submit.prevent="checkForm" class="mb-4">
		<div class="row">
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>
			

			<div class="col-12">
				<div class="form-group">
					<label for="checkdepositconfiguration_libelle">{{ $t('check.checkdepositconfiguration_libelle') }}</label>
					<input type="text" v-model="default_form.checkdepositconfiguration_libelle" class="form-control" id="checkdepositconfiguration_libelle">
				</div>
				<div class="form-group">
					<label for="checkdepositconfiguration_bank">{{ $t('check.checkdepositconfiguration_bank') }} *</label>
					<input type="text" v-model="default_form.checkdepositconfiguration_bank" class="form-control" id="checkdepositconfiguration_bank" :class="{ 'is-invalid': error && error.indexOf('Bank') > -1 }">
				</div>

				<div class="form-group">
					<label for="checkdepositconfiguration_accountnumber">{{ $t('check.checkdepositconfiguration_accountnumber') }} *</label>
					<input type="text" v-model="default_form.checkdepositconfiguration_accountnumber" class="form-control" id="checkdepositconfiguration_accountnumber" :class="{ 'is-invalid': error && error.indexOf('Number') > -1 }">
				</div>

				<div class="form-group">
					<label for="checkdepositconfiguration_bankaccountnumber">{{ $t('check.checkdepositconfiguration_bankaccountnumber') }} *</label>
					<input type="text" v-model="default_form.checkdepositconfiguration_bankaccountnumber" class="form-control" id="checkdepositconfiguration_bankaccountnumber" :class="{ 'is-invalid': error && error.indexOf('account') > -1 }">
				</div>

				<div class="form-group">
					<label for="checkdepositconfiguration_journal">{{ $t('check.checkdepositconfiguration_journal') }} *</label>
					<input type="text" v-model="default_form.checkdepositconfiguration_journal" class="form-control" id="checkdepositconfiguration_journal" :class="{ 'is-invalid': error && error.indexOf('code_journal') > -1 }">
				</div>

				<div class="form-group">
					<label for="checkdepositconfiguration_iban">{{ $t('check.checkdepositconfiguration_iban') }}</label>
					<input type="text" v-model="default_form.checkdepositconfiguration_iban" class="form-control" id="checkdepositconfiguration_iban" :class="{ 'is-invalid': error && error.indexOf('iban') > -1 }">
				</div>
				<div class="form-group">
					<label for="checkdepositconfiguration_bic">{{ $t('check.checkdepositconfiguration_bic') }}</label>
					<input type="text" v-model="default_form.checkdepositconfiguration_bic" class="form-control" id="checkdepositconfiguration_bic" :class="{ 'is-invalid': error && error.indexOf('iban') > -1 }">
				</div>

				<div class="form-group">
					<label for="checkdepositconfiguration_bankaddress">{{ $t('check.checkdepositconfiguration_bankaddress') }} *</label>
					<textarea v-model="default_form.checkdepositconfiguration_bankaddress" class="form-control" id="checkdepositconfiguration_bankaddress" :class="{ 'is-invalid': error && error.indexOf('address') > -1 }"></textarea>
				</div>

				<div class="form-group">
					<label for="checkdepositconfiguration_bridgeaccount">{{ $t('check.checkdepositconfiguration_bridgeaccount') }}</label>
						<e-select
                            v-model="bridge_account_selected"
                            id="bridgeaccount_id"
                            track-by="bridgeaccount_id"
                            label="bridgeaccount_name"
                            :placeholder="$t('check.selectionner_compte')"
                            :selectedLabel="$t('global.selected_label')"
                            :options="bridge_accounts"
                            :searchable="true"
                            :loading="loading_accounts"
                            :show-labels="false"
                            group-values="accounts"
                            group-label="bridgebank_name"
                            :group-select="false"
                            @select="setBridgeAccount"
                        >
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
				</div>
			</div>
		</div>
	</form>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'

    import Config from "@/mixins/Config.js"
	import TableAction from "@/mixins/TableAction.js"
	import Accounting from "@/mixins/Accounting.js"
	import Navigation from "@/mixins/Navigation.js"
	import OpenBank from "@/mixins/OpenBank.js"
	import _groupBy from 'lodash/groupBy'


	export default {
		name: "CheckDepositConfigurationAdd",
		mixins: [Config, TableAction, Accounting, Navigation, OpenBank],
		props: ['processing', 'ready', 'checkdepositconfiguration_id', 'checks_config', 'accountingplan_id'],
		data () {
			return {
				form_message: '',
				error: [],
				default_form: {
				    checkdepositconfiguration_id: null,
				    checkdepositconfiguration_bank: null,
				    checkdepositconfiguration_libelle: null,
				    checkdepositconfiguration_bankaccountnumber: null,
				    checkdepositconfiguration_journal: null,
				    checkdepositconfiguration_accountnumber: null,
				    checkdepositconfiguration_iban: null,
				    checkdepositconfiguration_bic: null,
				    checkdepositconfiguration_accountingplan: this.local_accountingplan_id,
					checkdepositconfiguration_bankaddress: null,
					checkdepositconfiguration_bridgeaccount: null
				},
				bridge_accounts: [],
				bridge_account_selected: null,
				loading_accounts: false
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
				this.local_accountingplan_id = this.accountingplan_id ? this.accountingplan_id : this.$route.params.accountingplan_id
				if(this.checkdepositconfiguration_id != null){
					this.default_form = this.deppCloneObj(this.checks_config).filter(checks_config => checks_config.checkdepositconfiguration_id == this.checkdepositconfiguration_id)[0]
				}

				this.loading_accounts = true
				const bridge_accounts = await this.getBridgeAccounts()

				const account_groupby = []
				for(let i in bridge_accounts) {
					let bank = account_groupby.findIndex(acc => acc.bridgebank_id == bridge_accounts[i].bridge_bank.bridgebank_id)
					if(bank == -1) {
						account_groupby.push({...bridge_accounts[i].bridge_bank, accounts: []})
						bank = account_groupby.findIndex(acc => acc.bridgebank_id == bridge_accounts[i].bridge_bank.bridgebank_id)
					}
					account_groupby[bank].accounts.push(bridge_accounts[i])
				}

				this.bridge_accounts = account_groupby
				this.loading_accounts = false

				if(this.default_form.checkdepositconfiguration_bridgeaccount) {
					this.bridge_account_selected = bridge_accounts.find(acc => acc.bridgeaccount_id == this.default_form.checkdepositconfiguration_bridgeaccount)
				}
			},

			async checkForm(){

				this.$emit('update:processing', false)
				if(!this.processing)
				{
					// this.processing = true
					this.$emit('update:processing', true)
					this.error = []

					if(this.default_form.checkdepositconfiguration_bank === null || this.default_form.checkdepositconfiguration_bank == '')
					{
						this.error.push("Bank")
					}

					if(this.default_form.checkdepositconfiguration_accountnumber === null || this.default_form.checkdepositconfiguration_accountnumber == '')
					{
						this.error.push("Number")
					}

					if(this.default_form.checkdepositconfiguration_bankaccountnumber === null || this.default_form.checkdepositconfiguration_bankaccountnumber == '')
					{
						this.error.push("account")
					}

					if(this.default_form.checkdepositconfiguration_journal === null || this.default_form.checkdepositconfiguration_journal == '')
					{
						this.error.push("code_journal")
					}
					
					if(this.error.length == 0)
					{
						this.form_message = ""

						let result_check_config = null

						this.default_form.checkdepositconfiguration_accountingplan = this.local_accountingplan_id
						result_check_config = await this.updateCreateCheckDepositConfig(this.default_form, this.default_form.checkdepositconfiguration_accountingplan);

						if(result_check_config)
						{
							this.$emit('update:processing', false)
							// this.processing = false
							return true
						}
						else
						{
							this.failureToast();
						}
					}
					else
					{
						this.form_message = "formulaire.erreur_champs_non_remplis"
						this.failureToast('formulaire.erreur_champs_non_remplis');
					}

					this.$emit('update:processing', false)
					// this.processing = false
					return false;
				}
			},
			setBridgeAccount(account) {
				this.default_form.checkdepositconfiguration_bridgeaccount = account.bridgeaccount_id
				this.default_form.checkdepositconfiguration_libelle = account.bridgeaccount_name
				this.default_form.checkdepositconfiguration_iban = account.bridgeaccount_iban
				this.default_form.checkdepositconfiguration_bank = account.bridge_bank.bridgebank_name
			}
		},
		computed: {
			
		},
		watch:{
			
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable') ,
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') ,
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
		}
	}
</script>